<template>
  <CButton
      :color="color"
      @click="handler"
      class="zq--responsive-button"
      :type="buttonType"
      :class="classes"
      :style="{backgroundColor: `${disableButton ? 'grey': ''}`}"
      :disabled="disableButton"
  >
    <div class="zq--responsive-button--icon">
      <i class="fa fa-plus"></i>
    </div>
    <div class="zq--responsive-button--label">{{ label }}</div>
  </CButton>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: 'button'
    },
    classes: {
      type: String,
      default: ''
    },
    colorProp: {
      type: String,
      default: ''
    },
    disableButton: Boolean
  },
  data() {
    return {
      color: ''
    }
  },
  computed: {
    ...mapGetters('theme', ['theme'])
  },
  mounted() {
    if (this.theme !== 'main' && !this.colorProp) {
      this.color = 'success';
    } else {
      this.color = this.colorProp;
    }
  },
  methods: {
    handler() {
      this.$emit('handler')
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins";

.zq--responsive-button, .zq--responsive-button.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  max-height: 40px;

  @include media-breakpoint-down('576px') {
    width: 40px;
    height: 38px;

    &__common {
      width: max-content;
    }

  }
}

.zq--responsive-button--icon {
  font-size: 0.7rem;
  font-weight: bold;
  i {
    margin: 0 !important;
  }

  @include media-breakpoint-down('576px') {
    margin-right: 0;
  }
}

.zq--responsive-button--label {
  margin-left: 12px;
  width: max-content;
  @include media-breakpoint-down('576px') {
    display: none;
  }
}
</style>